/** @jsx jsx */
import { jsx } from '@emotion/core';

import Category from '../Category';

interface Props {
  categories: Category[];
}

const BlogCategoryList = ({ categories }: Props) => {
  return (
    <div>
      <span css={{}}>Category</span>
      <div css={{}}>
        {categories.map((category, index) => {
          const { value, totalCount } = category;
          return (
            <Category
              key={index.toString()}
              value={value}
              totalCount={totalCount}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BlogCategoryList;
