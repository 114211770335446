/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'gatsby';

interface Props {
  value: string | null;
  totalCount: number;
}

const BlogCategory = ({ value, totalCount }: Props) => {
  return (
    <Link to={`/blog/#${value}`}>
      <span css={{ padding: 8 }}>
        <span>{value ? value : '미분류'}</span>
      </span>
    </Link>
  );
};

export default BlogCategory;
