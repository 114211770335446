/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, RefObject } from 'react';

import CategoryGroup from '../CategoryGroup';

interface Props {
  categoryGroups: CategoryGroup[];
  groupRefs: RefObject<HTMLHeadingElement>[];
}

const BlogCategoryGroupList = ({ categoryGroups, groupRefs }: Props) => {
  return (
    <Fragment>
      {categoryGroups.map((categoryGroup, index) => (
        <CategoryGroup
          key={index.toString()}
          groupRef={groupRefs[index]}
          {...categoryGroup}
        />
      ))}
    </Fragment>
  );
};

export default BlogCategoryGroupList;
