/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, RefObject } from 'react';

import PostTitleList from '../PostTitleList';

interface Props extends CategoryGroup {
  groupRef: RefObject<HTMLHeadingElement>;
}

const BlogCategoryGroup = ({ category, postTitles, groupRef }: Props) => {
  return (
    <Fragment>
      <h2 ref={groupRef} css={{ color: 'hotpink' }}>
        {category}
      </h2>
      <PostTitleList postTitles={postTitles} />
    </Fragment>
  );
};

export default BlogCategoryGroup;
